import React from "react";
import PropTypes from "prop-types";
import { useQuery, gql } from "@apollo/client";
import {
  Anchor,
  Box,
  Button,
  ResponsiveContext,
  Text,
} from "grommet";
import { FormClose } from "grommet-icons";
import {
  Deck,
  Icon,
  Modal,
} from "@/cloverleaf-ui/components";
import { isMobile, isTablet } from "@/cloverleaf-ui/utils";
import { PricingCard } from "./components/PricingCard";
import { useLocale } from "@/lib/hooks";

const yearlyPricingPlans = (t) => ([
  {
    color: "blue600",
    planType: t("organization-billing.plans.team-plan.type"),
    pricing: t("organization-billing.plans.team-plan.price"),
    subheader: t("organization-billing.plans.team-plan.subheader"),
    benefits: [
      t("organization-billing.plans.team-plan.benefits.less-than-25"), // add blank list item for spacing
      t("organization-billing.plans.team-plan.benefits.coaching"),
      t("organization-billing.plans.team-plan.benefits.assessments"),
      t("organization-billing.plans.team-plan.benefits.integrations"),
      t("organization-billing.plans.team-plan.benefits.thinking-comparison"),
    ],
  },
  {
    color: "green600",
    planType: t("organization-billing.plans.growth-plan.type"),
    pricing: t("organization-billing.plans.growth-plan.price"),
    subheader: t("organization-billing.plans.growth-plan.subheader"),
    benefits: [
      t("organization-billing.plans.growth-plan.benefits.team-plus"),
      t("organization-billing.plans.growth-plan.benefits.thinking-comparison"),
      t("organization-billing.plans.growth-plan.benefits.in-depth-analysis"),
      t("organization-billing.plans.growth-plan.benefits.coaching"),
      t("organization-billing.plans.growth-plan.benefits.onboarding"),
      t("organization-billing.plans.growth-plan.benefits.online-support"),
    ],
  },
  {
    color: "purple500",
    planType: t("organization-billing.plans.enterprise-plan.type"),
    pricing: t("organization-billing.plans.enterprise-plan.price"),
    subheader: t("organization-billing.plans.enterprise-plan.subheader"),
    benefits: [
      t("organization-billing.plans.enterprise-plan.benefits.growth-plus"),
      t("organization-billing.plans.enterprise-plan.benefits.customize-config"),
      t("organization-billing.plans.enterprise-plan.benefits.accelerated-hiring"),
      t("organization-billing.plans.enterprise-plan.benefits.sso"),
      t("organization-billing.plans.enterprise-plan.benefits.bulk-user"),
      t("organization-billing.plans.enterprise-plan.benefits.on-demand-analytics"),
      t("organization-billing.plans.enterprise-plan.benefits.support"),
      t("organization-billing.plans.enterprise-plan.benefits.flexible-contract"),
      t("organization-billing.plans.enterprise-plan.benefits.custom-onboarding"),
      t("organization-billing.plans.enterprise-plan.benefits.on-demand-coaching"),
    ],
  },
]);

const TEAM_COUNT_QUERY = gql`
  query teamCount {
    currentUser {
      id
      organization {
        id
        plgPlan {
          subscription {
            planId
          }
        }
        teams {
          id
        }
        users {
          id
        }
      }
    }
  }
`;

function PricingModal({
  onClose = () => undefined,
}) {
  const { t } = useLocale();

  // const [isYearlyPricing, setIsYearlyPricing] = React.useState(false);
  const [organization, setOrganization] = React.useState();
  const screenSize = React.useContext(ResponsiveContext);
  const handleOnCancel = React.useCallback(event => onClose(event), [onClose]);

  const { data } = useQuery(TEAM_COUNT_QUERY);

  React.useEffect(() => {
    if (data?.currentUser?.organization) {
      setOrganization(data?.currentUser?.organization);
    }
  }, [data]);

  return (
    <Modal
      background="white"
      hideClose
      onClose={handleOnCancel}
      width="large"
    >
      <Box
        background="white"
        gap="large"
        pad="large"
        round="xsmall"
      >
        <Box direction="row" flex={false} justify="between">
          <Text color="grey800" size="large">{t("organization-billing.pricing-modal.title")}</Text>
          <Button icon={<FormClose />} onClick={handleOnCancel} plain />
        </Box>
        <Box direction="column" justify="between">
          {/* @TODO future implementation
        <Box direction="row" justify="between">
          <Text size="medium">Select a plan that best fits your team!</Text>
          <Box align="center" direction="row" gap="medium">
            <Text size="medium">Yearly Pricing</Text>
            <Toggle
              id="isPrivate-toggle"
              a11yTitle="Toggle configuration"
              checked={isYearlyPricing}
              onChange={checked => setIsYearlyPricing(checked)}
              showState
            />
          </Box>
        </Box>  */}
          {isMobile(screenSize) || isTablet(screenSize) ? (
            <Deck controlProps={{ justify: "center" }}>
              {yearlyPricingPlans(t).map(plan =>
                <PricingCard key={plan?.planType} organization={organization} pricingPlan={plan} />)}
            </Deck>
          ) : (
            <Box direction="row" justify="between" gap="medium">
              {yearlyPricingPlans(t).map(plan =>
                <PricingCard key={plan?.planType} organization={organization} pricingPlan={plan} />)}
            </Box>
          )}
        </Box>
        <Box align="center">
          <Anchor
            color="green600"
            href="https://cloverleaf.me/pricing"
            label={t("organization-billing.pricing-modal.learn-more")?.toUpperCase()}
            icon={(
              <Icon
                icon={["fa", "arrow-up-right-from-square"]}
                color="green600"
              />
            )}
            reverse
            target="_blank"
          />
        </Box>
      </Box>
    </Modal>
  );
}

PricingModal.propTypes = {
  onClose: PropTypes.func,
};

export { PricingModal };
