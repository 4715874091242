import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  ResponsiveContext,
  Text,
  ThemeContext,
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { isDesktop, isTablet } from "@/cloverleaf-ui/utils";
import { PLANS } from "../../../../lib";
import {
  SIDEBAR_TYPES,
  useSidebar,
  useLocale
} from "../../../../lib/hooks";

const PRICING_PLANS = {
  TEAM_PLAN: "TEAM PLAN",
  GROWTH_PLAN: "GROWTH PLAN",
  ENTERPRISE_PLAN: "ENTERPRISE PLAN",
};

/**
 * Enum conversion to match DB
 */
const PLAN_ID = {
  "TEAM PLAN": "team_monthly",
  "GROWTH PLAN": "growth_monthly",
  "ENTERPRISE PLAN": "enterprise_monthly",
};
function PricingCard({
  organization = undefined,
  pricingPlan = undefined,
}) {
  const { t } = useLocale();
  const router = useRouter();

  const [,
    setIsPlanAndBillingSidebarOpen,
  ] = useSidebar(SIDEBAR_TYPES.PLAN_AND_BILLING_SIDEBAR);

  const {
    benefits, color, planType, pricing, subheader,
  } = pricingPlan || {};

  const [currentPlanId, setCurrentPlanId] = React.useState();
  const [label, setLabel] = React.useState(t("organization-billing.purchase"));
  const [teamCount, setTeamCount] = React.useState();
  const [userCount, setUserCount] = React.useState();

  const screenSize = React.useContext(ResponsiveContext);

  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (organization?.plgPlan?.subscription?.planId) {
      setCurrentPlanId(organization?.plgPlan?.subscription?.planId);
    }

    if (organization?.teams) {
      setTeamCount(organization?.teams.length);
    }

    if (organization?.users) {
      setUserCount(organization?.users.length);
    }
  }, [organization]);

  React.useEffect(() => {
    // disabling team plan even if current plan is another
    if (currentPlanId === PLAN_ID[planType] || (planType === PRICING_PLANS.TEAM_PLAN
      && (teamCount > 4 || userCount > 25))) {
      setDisabled(true);
    }

    // disabling other cards if current plan
    if ((currentPlanId === PLAN_ID[planType])) {
      setDisabled(true);
    }
  }, [currentPlanId, planType, teamCount, userCount]);

  React.useEffect(() => {
    if (currentPlanId === PLAN_ID[planType]) {
      setLabel(t("organization-billing.current-plan"));
    }

    // future implementation to downgrade
    // if (currentPlanId === PLAN_ID['GROWTH PLAN']) {
    //   return setLabel('Downgrade');
    // }

    else if (currentPlanId === PLAN_ID[PRICING_PLANS.TEAM_PLAN]) {
      setLabel(t("organization-billing.upgrade-plan"));
    }

    else {
      setLabel(t("organization-billing.purchase"));
    }
  }, [currentPlanId, planType, t]);

  const buttonTheme = {
    button: {
      secondary: {
        color,
      },
      hover: {
        secondary: {
          background: color,
          border: undefined,
          color: "white",
        },
      },
    },
  };

  const handlePlanPurchase = () => {
    const planId = planType === PRICING_PLANS.TEAM_PLAN ? PLANS.teamPlan : PLANS.growth;

    setIsPlanAndBillingSidebarOpen(false);

    const organizationId = organization?.id;

    return router.push(`/billing?planId=${planId}${organizationId ? `&organizationId=${organizationId}` : ""}`);
  };

  const renderListItem = (benefit, index) => {
    if (index === 0 && planType === PRICING_PLANS.TEAM_PLAN) {
      return (
        <Box
          align="center"
          direction="row"
          flex={false}
          key={benefit}
          pad={{ horizontal: "medium", vertical: "small" }}
        >
          <Text size="medium" weight="bold">{benefit}</Text>
        </Box>
      );
    }

    if (index === 0) {
      return (
        <Box
          align="center"
          background={color}
          direction="row"
          flex={false}
          gap="small"
          key={benefit}
          pad={{ horizontal: "medium", vertical: "small" }}
        >
          <Icon icon={["fas", "circle-check"]} color="white" />
          <Text size="medium">{benefit}</Text>
        </Box>
      );
    }

    return (
      <Box
        align="center"
        direction="row"
        flex={false}
        gap="small"
        key={benefit}
        pad={{ horizontal: "medium", vertical: "small" }}
      >
        <Icon icon={["far", "circle-check"]} color="green600" />
        <Text size="medium">{benefit}</Text>
      </Box>
    );
  };

  return (
    <Card
      round={isDesktop(screenSize) ? "small" : "none"}
      width={isDesktop(screenSize) || isTablet(screenSize) ? "large" : "100%"}
    >
      <CardHeader
        align="start"
        background={color}
        direction="column"
        justify="start"
        gap="xxsmall"
        pad="medium"
        height="xsmall"
      >
        <Text color="white" weight="bold">
          {planType}
        </Text>
        <Text color="white" size="small">
          {subheader}
        </Text>
      </CardHeader>
      <CardBody>
        <Box
          border="bottom"
          pad="medium"
          gap="small"
          height={{ min: "140px" }}
        >
          <Box align="center" direction="row" gap="small" justify="start">
            <Text color={color} size="44px" weight="bold">{pricing}</Text>
            {planType !== PRICING_PLANS.ENTERPRISE_PLAN && <Text>{t("organization-billing.per-month")}</Text>}
          </Box>
          <ThemeContext.Extend value={buttonTheme}>
            {planType !== PRICING_PLANS.ENTERPRISE_PLAN ? (
              <Button color={color} disabled={disabled} label={label} onClick={handlePlanPurchase} secondary />
            ) : (
              <Button color={color} disabled={disabled} href="https://cloverleaf.me/getstarted" label={t("organization-billing.schedule-demo")} secondary target="_blank" style={{ textTransform: "uppercase" }} />
            )}
          </ThemeContext.Extend>
        </Box>
        <Box overflow="auto">
          {benefits.map((benefit, index) => renderListItem(benefit, index))}
        </Box>
      </CardBody>
    </Card>
  );
}

PricingCard.propTypes = {
  organization: PropTypes.object,
  pricingPlan: PropTypes.shape({
    benefits: PropTypes.array,
    color: PropTypes.string,
    planType: PropTypes.string,
    pricing: PropTypes.string,
    subheader: PropTypes.string,
  }),
};
export { PricingCard };
